.services-container {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10%;
  }
  
  .header {
    text-align: center;
  }
  
  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    padding: 40px;
  }
  
  .card {
    border: 1px solid #ccc;
    background-color: #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: calc(33.33% - 20px); 
    box-sizing: border-box;
  }
  
  .card h2 {
    font-size: 18px;
    margin-top: 0;
  }
  
  .card p {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 20px); 
    }
  }
  
  @media (max-width: 480px) {
    .card-container {
      flex-direction: column;
    }
  
    .card {
      width: 100%;
    }
  }
  